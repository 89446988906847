/** @jsx jsx */
import { css } from '@emotion/core';
import { enFont01, mq, jpFont01 } from "../constants/styleConstants";

export const designerStyle = {
  mainVisualBlock: css`
    max-width: 120rem;
    padding: 0.75rem 1.5rem;
  `,
  introBlock: css`
    max-width: 80rem;
    margin: 2rem auto 4rem;
    position: relative;
    ${mq('medium')} {
      margin-bottom: 8rem;
    }
  `,
  introLogo: css`
    width: 32%;
    margin-left: 1rem;
    img, svg {
      width: 100%;
      height: auto;
    }
    ${mq('medium')} {
      width: 22%;
    }
  `,
  introNumber: css`
    ${enFont01.bold};
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 1.25rem;
    line-height: 1;
    letter-spacing: 0.05em;
    ${mq('medium')} {
      font-size: 4.375rem;
      right: 5rem;
    }
  `,
  introInner: css`
    margin-top: 1rem;
    padding: 0 1.25rem;
    ${mq('medium')} {
      padding: 0 5rem;
    }
  `,
  introData: css`
    padding: 1rem 1.25rem;
    ${mq('medium')} {
      display: flex;
      padding: 2rem 0;
      & > div {
        &:first-of-type {
          flex: 1;
          padding-right: 4.5%;
          margin-right: 5%;
          border-right: 1px solid;
        }
        &:last-of-type {
          width: 40%;
          max-width: 22.25rem;
          margin-right: 2rem;
        }
      }
    }
  `,
  introTitle: css`
    font-size: 1.875rem;
    ${enFont01.bold};
    //letter-spacing: 0.2em;
    span {
      font-size: 1.0625rem;
      position: relative;
      display: block;
      //letter-spacing: 0.1em;
      ${jpFont01.bold};
    }
    ${mq('medium')} {
      font-size: 2rem;
    }
  `,
  introDescriptionJp: css`
    margin-top: 3rem;
    //text-align: justify;
  `,
  introDescriptionEn: css`
    margin-top: 3rem;
    ${enFont01.bold};
    font-size: 0.875rem;
    //text-align: justify;
  `,
  introDate: css`
    ${enFont01.bold};
    font-size: 3.125rem;
    text-align: right;
    line-height: 1.2;
  `,
  introVenue: css`
    ${enFont01.bold};
    font-size: 1.125rem;
  `,
  introListBlock: css`
    ${enFont01.light};
    font-size: 0.8125rem;
    margin-top: 3rem;
    li {
      line-height: 2.2;
    }
  `,
  slideShowThumbList: css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 75rem;
    margin: 3rem auto 0;
    ${mq('medium')} {
      margin-top: 0;
    }
  `,
  slideShowThumbListItem: css`
    width: 49%;
    margin-bottom: 2%;
    &:last-of-type {
      border: 1px solid;
    }
    ${mq('large')} {
      width: 26%;
      margin-bottom: 0;
      &:last-of-type {
        width: 19%;
      }
    }
  `,
  slideShowThumbListLabel: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background: #fff;
    ${enFont01.bold};
    font-size: 0.875rem;
    line-height: 1.4;
    & > div {
      &:first-of-type {
        font-size: 1.25rem;
      }
      &:last-of-type {
        margin-top: 0.25rem;
      }
    }
  `,
  workBlock: css`
    max-width: 80rem;
    margin: 2rem auto 4rem;
    padding: 0 1.25rem;
    overflow: hidden;
    ${mq('medium')} {
      padding: 0 5rem;
    }
  `,
  workItem: css`
    padding-bottom: 3rem;
    ${mq('medium')} {
      display: flex;
      &:nth-of-type(2n) {
        & > div:first-of-type {
          order: 2;
          & > div {
            margin: -12% -25% 0 -8%;
          }
        }
      }
    }
  `,
  workImageBlock: css`
    position: relative;
    z-index: 0;
    margin: 0 -1rem 1rem;
    ${mq('medium')} {
      width: 48%;
      & > div {
        margin: -12% -8% 0 -25%;
      }
    }
  `,
  workTextBlock: css`
    position: relative;
    z-index: 1;
    h2 {
       ${enFont01.bold};
       font-size: 1.5rem;
       line-height: 1.4;
       margin-bottom: 0.25rem;
    }
    ${mq('medium')} {
      width: 52%;
      padding: 0;
      h2 {
       font-size: 1.875rem;
      }
    }
  `,
  workSubTitle: css`
    ${jpFont01.bold};
    font-size: 1rem;
    ${mq('medium')} {
      font-size: 1rem;
    }
  `,
  workDescriptionJp: css`
    margin-top: 3rem;
  `,
  workDescriptionEn: css`
    margin-top: 3rem;
    ${enFont01.bold};
    font-size: 0.875rem;
  `,
  profileBlock: css`
    max-width: 60rem;
    margin: 4rem auto 4rem;
    padding: 0 1.25rem;
    overflow: hidden;
    h2 {
       ${enFont01.bold};
       font-size: 1.875rem;
    }
    ${mq('medium')} {
      margin-top: 6rem;
      padding: 0 5rem;
    }
  `,
  profileImageBlock: css`
    margin-bottom: 4rem;
  `,
  profileNameBlock: css`
    margin-bottom: 2rem;
    div {
      display: inline-block;
      span {
        ${enFont01.bold};
        font-size: 1.0625rem;   
        display: block;
        text-align: left;
        line-height: 1;
      }
    }
  `,
  profileTitle: css`
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.0625rem;
    ${jpFont01.bold};
  `,
  profileTextJp: css`
    margin-top: 2rem;
  `,
  profileTextEn: css`
    margin-top: 2rem;
    ${enFont01.bold};
    font-size: 0.875rem;
  `,
  profileSubTitleJp: css`
    margin-top: 3rem;
    font-size: 1.1875rem;
  `,
  profileSubTitleEn: css`
    ${enFont01.bold};
    margin-top: 3rem;
    font-size: 1.1875rem;
  `,
  profileImage: css`
    margin-top: 3rem;
  `,
}
