import React, { useContext, useRef } from "react";
import GlobalContext from "../../context/globalContext";
import VideoFullWidth from "../Video/videoFullWidth";
import { graphql, useStaticQuery } from "gatsby";

const Designer01Video = () => {
  const data = useStaticQuery(graphql`
  query {
    video01: file(relativePath: { eq: "designer01/video01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const global = useContext(GlobalContext);
  const videoDelayValue = global.isLoading ? 1000 : 1000;
  const videoDelay = useRef(videoDelayValue);

  return (
    <VideoFullWidth
      videoId="64083500"
      image={data.video01}
      title="xxxxxxxxx"
      delay={videoDelay.current}
    />
  );
}

export default React.memo(Designer01Video);
