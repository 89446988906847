import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import TransitionLink, {TransitionPortal} from "gatsby-plugin-transition-link";
// import Link from 'gatsby-plugin-transition-link';
import { gsap } from "gsap";

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { designerStyle } from "../components/designerStyle";

import Img from "gatsby-image";
import TransitionCover from "../components/TransitionCover/transitionCover";
import GlobalContext from "../context/globalContext";
import { duration, entryDelay, imageFadeDuration } from "../constants/componentConstants";
import Designer02Intro from "../components/Designer/Designer02Intro";
import Designer01Video from "../components/Designer/Designer01Video";

const Designer02Page = () => {
  const data = useStaticQuery(graphql`
  query {
    video01: file(relativePath: { eq: "designer02/video01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image01: file(relativePath: { eq: "designer02/image01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image02: file(relativePath: { eq: "designer02/image02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    profile01: file(relativePath: { eq: "designer02/profile01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    profile02: file(relativePath: { eq: "designer02/profile02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const global = useContext(GlobalContext);
  // const videoDelayValue = global.isLoading ? 1000 : 3000;
  // const videoDelay = useRef(videoDelayValue);

  useEffect(() => {
    global.setHideHeaderLogo(true);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const commonLayer = useRef(null);
  const entryAnimation = useCallback(() => {
    console.log("entryAnimation");
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    console.log("exitAnimation");
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  return (
    <>
      <SEO title="CHRISTELLE KOCHER　クリステル・コシェ" />

      <Designer01Video />

      <Designer02Intro />

      <div css={designerStyle.workBlock}>
        <div css={designerStyle.workItem}>
          <div css={designerStyle.workImageBlock}>
            <div>
              <Img
                durationFadeIn={imageFadeDuration}
                fluid={data.image01.childImageSharp.fluid}
              />
            </div>
          </div>
          <div css={designerStyle.workTextBlock}>
            <h2>Subtitle Here Subtitle Here</h2>
            <div css={designerStyle.workSubTitle}>日本サブタイトル入る</div>
            <div css={designerStyle.workDescriptionJp}>
              次なる創造性と可能性をもたらす。ことができる。伝統を軸におきながらも、これまでに類を見ない異素材同士のレイヤリングと技術は、織物にフリッツ・ハンセンのエッグチェアに落とし込まれ、ファッションデザイナーならではのユニークな視点を垣間見るファッションデザイナーによって新たな織物のクリエイションを提案。三者三様の表情豊かなテキスタイルは、(ひきばく)、ビロード、綴織(つづれおり)の3つの技法に焦点をあて、世界で活躍が期待される3人の新鋭古くから現在まで匠な技術を世界に発信し続けている。同展では、和装で多用される伝統的な織物として、引箔京都・西陣をルーツとする川島織物セルコンは着物の帯や緞帳(どんちょう)、インテリアファブリックまでを手掛け、
            </div>
            <div css={designerStyle.workDescriptionEn}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</div>
          </div>
        </div>

        <div css={designerStyle.workItem}>
          <div css={designerStyle.workImageBlock}>
            <div>
              <Img
                durationFadeIn={imageFadeDuration}
                fluid={data.image02.childImageSharp.fluid}
              />
            </div>
          </div>
          <div css={designerStyle.workTextBlock}>
            <h2>Subtitle Here Subtitle Here</h2>
            <div css={designerStyle.workSubTitle}>日本サブタイトル入る</div>
            <div css={designerStyle.workDescriptionJp}>
              次なる創造性と可能性をもたらす。ことができる。伝統を軸におきながらも、これまでに類を見ない異素材同士のレイヤリングと技術は、織物にフリッツ・ハンセンのエッグチェアに落とし込まれ、ファッションデザイナーならではのユニークな視点を垣間見るファッションデザイナーによって新たな織物のクリエイションを提案。三者三様の表情豊かなテキスタイルは、(ひきばく)、ビロード、綴織(つづれおり)の3つの技法に焦点をあて、世界で活躍が期待される3人の新鋭古くから現在まで匠な技術を世界に発信し続けている。同展では、和装で多用される伝統的な織物として、引箔京都・西陣をルーツとする川島織物セルコンは着物の帯や緞帳(どんちょう)、インテリアファブリックまでを手掛け、
            </div>
            <div css={designerStyle.workDescriptionEn}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</div>
          </div>
        </div>
      </div>

      <div css={designerStyle.profileBlock}>
        <div css={designerStyle.profileImageBlock}>
          <Img
            durationFadeIn={imageFadeDuration}
            fluid={data.profile01.childImageSharp.fluid}
          />
        </div>

        <div css={designerStyle.profileNameBlock}>
          <div>
            <h2>CHRISTELLE KOCHER</h2>
            <span>クリステル・コシェ</span>
          </div>
        </div>

        <p css={designerStyle.profileTextJp}>
          クリステル・コシェ<br />
          2010 年より、シャネル傘下の羽根細工アトリエ「ルマリエ」のアーティスティック・ディレクター。自身のブランドは 2014 年設立。今年、エミリオ・プッチとコラボによるカプセル・コレクションを発表。
        </p>
        <p css={designerStyle.profileTextEn}>
          Couture has long existed on a rarefied pedestal, but Christelle Kocher is determined to bring it back down to earth. “People mix Chanel with jeans, with a T-shirt. That’ s what I like. That’ s how you wear clothes,” the Paris-based designer remarks between sips of tea on a recent visit to New York. Kocher, 36, takes the transatlantic trip often, crashing at a friend’ s Chinatown apartment while scouting inspiration for her line, Koché. Founded last year as a luxurious proposition to the reality of everyday dressing, Koché blends a global streetwear vibe with top-tier French heritage craft.
        </p>

        <h3 css={designerStyle.profileSubTitleJp}>- XXXXXXXXXXXXXX?</h3>
        <p css={designerStyle.profileTextEn}>
          The latter is something Kocher knows well. Since 2010, the Strasbourg-bred Central Saint Martins grad has served as the artistic director of Maison Lemarié, the 135-year-old artisan house known for engineering Chanel’ s signature camellias, feather work, and other fripperies. Kocher routinely taps Lemarié and other historic maisons such as Lesage and Montex for Koché’ s embroideries, beading, and embellishments. But despite the fanatical attention to detail, Kocher’ s work is not precious. A self-professed art and music obsessive (she’ s currently taken with videos by Grimes and Ryan Trecartin), Kocher has proven extremely adept at filtering the casual, thrown-together urban uniform of real women through her own painterly sense of high-energy color and pumped-up proportions. Her fall collection features a fluorescent pink suit, ultra-wide culottes, patchwork knits, crystal-flecked frocks, and a multicolored fuzzy fur coat, all designed to be worn with a pull-on ease. It’ s no surprise Kocher styled her lookbook models in quotidian sneakers, creepers, and slouchy beanies. “It’ s about being beautiful, really practical, and really comfortable. It’ s a lot of contrast,” she explains.
        </p>

        <h3 css={designerStyle.profileSubTitleJp}>- XXXXXXXXXXXXXX?</h3>
        <p css={designerStyle.profileTextEn}>
          Kocher began her career at Emporio Armani, followed by stints at Dries van Noten, Sonia Rykiel, Bottega Veneta, and Chloé, where she oversaw the delicately draped flou garments. A brand of her own has been decades in the making, and Kocher is taking full advantage of the moment, putting plans in motion for accessories and a jewelry collection in collaboration with Goossens (a nomination for this year’ s LVMH Prize didn’ t hurt either). “I’ m really independent,” she says. “I’ m happy to start this adventure.”
        </p>
        <Img
          durationFadeIn={imageFadeDuration}
          css={designerStyle.profileImage}
          fluid={data.profile02.childImageSharp.fluid}
        />

        <h3 css={designerStyle.profileSubTitleJp}>- XXXXXXXXXXXXXX?</h3>
        <p css={designerStyle.profileTextEn}>
          Kocher began her career at Emporio Armani, followed by stints at Dries van Noten, Sonia Rykiel, Bottega Veneta, and Chloé, where she oversaw the delicately draped flou garments. A brand of her own has been decades in the making, and Kocher is taking full advantage of the moment, putting plans in motion for accessories and a jewelry collection in collaboration with Goossens (a nomination for this year’ s LVMH Prize didn’ t hurt either). “I’ m really independent,” she says. “I’ m happy to start this adventure.”
        </p>
      </div>


      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default Designer02Page
